@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer base{
  body{
    @apply font-poppins
  }
  h1,h2,h3,h4,h5,h6{
    @apply font-roboto
  }
}

@layer components{
  .input-box{
    @apply block w-full border border-gray-300 px-4 text-gray-600 text-sm rounded placeholder-gray-400 focus:ring-0
  }
}
